import React, { useState } from 'react';
import { Stack } from '@mui/system';
import {
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Button
} from '@mui/material';

const EditUserAttendance = ({user, data, onCancel, setData}) => {
  const [attendanceData, setAttendanceData]= useState({status:"Full Day", value:0.0, note:""});

  const handleChange = (e) => {
    const {name, value}= e.target;
    setAttendanceData({...attendanceData, [name]:value})
  }

  const label = attendanceData?.status==='Overtime' ? 'OT worked hours' : attendanceData?.status==='Undertime' ?'UT worked hours' : attendanceData?.status==="Late"? "Minutes Late":'';

  const unit = attendanceData?.status==="Overtime" || attendanceData?.status==='Undertime' ? 'hr': attendanceData?.status==="Late" ? "min" :'';

  const statuses  =[
    "Full Day",
    "Half Day",
    "Sick Leave",
    "Vacation Leave",
    "Mental Wellness Leave",
    "Undertime",
    "Overtime",
    "Late",
    "Absent (Paid)",
    "Absent (Unpaid)"
  ];

  const onCancelLocal = () => {
    setAttendanceData({status: "Full Day", value: 0.0, note: ""});
    onCancel()
  }

  const onSubmit = () => {
    fetch(`/approval/${data.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({attendance: attendanceData})
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === 'success') {
        onCancelLocal();
        setData(data.data)
      } else {
        alert(data.errors);
      }
    })
    .catch((error) => {
      alert('Ooops! please try again later')
    });
  }

  return (
    <>
      <Paper sx={{maxWidth:"40rem", width:"13rem", padding:2, margin:0}} elevation={24}>
        <FormControl fullWidth>
          <InputLabel id="attendance">Attendance Status</InputLabel>
          <Select
            value={attendanceData?.status}
            onChange={handleChange}
            labelId="attendance"
            label="Attendance"
            size="small"
            name="status"
            fullWidth
          >
            {statuses?.map((item, key)=>(<MenuItem value={item}>{item}</MenuItem>))}
          </Select>

          {/* OT */}
          <Stack direction={'row'} flexWrap={'wrap'}>
            {label && unit && (
              <FormControl sx={{width:'100%'}}>
                <OutlinedInput
                  endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
                  size="small"
                  sx={{marginTop:2}}
                  onChange={handleChange}
                  name='value'
                  fullWidth
                />
                <FormHelperText>{label}</FormHelperText>
              </FormControl>
            )}
            <TextField
              multiline
              rows={4}
              label='Notes'
              value={attendanceData?.note}
              name="note"
              onChange={handleChange}
              sx={{marginTop:1}}
              fullWidth
            />
            <div style={{marginTop: '10px', textAlign: 'right', width: '100%'}}>
              <Button variant="contained" color="primary" sx={{marginRight:1}} size="small" onClick={onSubmit}>Save</Button>
              <Button color="secondary" size="small" onClick={onCancelLocal}>Cancel</Button>
            </div>
          </Stack>

          {/* LATE */}
        </FormControl>
      </Paper>
    </>
  );
}

export default EditUserAttendance;
