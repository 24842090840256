import moment from 'moment';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItem, List, ListItemText, Paper, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Stack } from '@mui/system';

const UserAttendanceDetails = ({data, user}) => {
  return (
    <>
      <Paper sx={{maxWidth:"40rem", maxHeight: '600px', padding:2, margin: '100px auto', overflow: 'scroll'}} elevation={2}>
        <Stack direction={'column'}  flexWrap={'wrap'} justifyContent={'center'}>
          <InputField label={'ID'} value={data.id} />
          <InputField label={'Name'} value={`${user.last_name} ${user.first_name}`} name="name" />
          <InputField label={'Date'} value={data?.attendance_date} name="date" />
          <InputField label={'Team Lead'} value={data?.team_lead} name="team_lead" />
          <InputField label={'Deparment'} value={data ?.department} name="deparment" />
          <InputField label={'Scheduled Day Off'} value={data?.scheduled_day_off} name="scheduled_day_off" />
          <InputField label={'Recommended Status'} value={data?.recommended_status} name="recommended_status" />
          <InputField label={'Final Status'} value={data?.final_status} name="final_status" />

          {/* TIME IN AND TIME OUT LOGS */}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Time In & Time out Logs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">IN:</Typography>
              <List dense>
                {data.in_logs?.map((item, key)=>(
                  <ListItem disablePadding key={key}>
                    <ListItemText>{moment(item).format('LLLL')}</ListItemText>
                  </ListItem>
                ))}
              </List>
              <Typography variant="body2">OUT:</Typography>
              <List dense>
                {data.out_logs?.map((item, key)=>(
                  <ListItem disablePadding key={key}>
                    <ListItemText>{moment(item).format('LLLL')}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>

          {/* CONFIRMATIONS */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Confirmations/Approvals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List dense>
                {data?.approvals?.map((item, key)=>(
                  <ListItem disablePadding key={key}>
                    <ListItemText>{item?.user.first_name} {item?.user.last_name}  -  {moment(item.created_at).format('LLL')}</ListItemText>
                    <ListItemText>{item?.approved_status} ({item?.extra_info}) {item?.notes}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>

          {/* NOTES */}
          {/* <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Notes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField multiline rows={4} label="Notes" value={data?.notes} disabled fullWidth></TextField>
            </AccordionDetails>
          </Accordion> */}
        </Stack>
      </Paper>
    </>
  );
}

const InputField = ({label, value, name, disabled=true}) => {
  return (
    <TextField size='small' sx={{margin:1}} label={label} value={value} name={name}  disabled={disabled} variant="outlined" />
  )
}

export default UserAttendanceDetails;
