import React from 'react';
import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment/moment';

export default ({title, date, logs, to_show}) => {
  const listItemStyle = {
    textAlign:'center',
    fontSize:".7rem"
  }

  const timezone = new Date(0);
  timezone.setUTCSeconds(logs[0]);
  const tz = timezone.toString().split(' ')[5];

  const indexToShow = () => {
    if (to_show === 'first') {
      return 0;
    } else if (to_show === 'last') {
      return logs.length - 1;
    }
  }

  return (
    <Box>
      <Typography align='center' variant='body2'>{title}:</Typography>
      {logs.length > 0 &&
        <List dense>
          <ListItem disablePadding>
            <ListItemText
              sx={listItemStyle}
              primary={date}
              secondary={`${moment(logs[indexToShow()]).format("h:mm:ss.A")}`}
            />
          </ListItem>
        </List>
      }
      {logs.length == 0 && <Typography align='center' variant='body2' color='grey'>None</Typography>}
    </Box>
  )
}
