import React from 'react';
import ViewMode from './_view_mode';

export default ({users}) => {
  return (
    <div>
      <table className="table table table-bordered table-primary">
        <thead>
          <tr>
            <th scope="col" style={{width: '200px'}}>Name</th>
            <th scope="col">Monday</th>
            <th scope="col">Tuesday</th>
            <th scope="col">Wednesday</th>
            <th scope="col">Thursday</th>
            <th scope="col">Friday</th>
            <th scope="col">Saturday</th>
            </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.last_name} {user.first_name}</td>
              <td><ViewMode user={user} raw_data={user.week_attendance.monday}    /></td>
              <td><ViewMode user={user} raw_data={user.week_attendance.tuesday}   /></td>
              <td><ViewMode user={user} raw_data={user.week_attendance.wednesday} /></td>
              <td><ViewMode user={user} raw_data={user.week_attendance.thursday}  /></td>
              <td><ViewMode user={user} raw_data={user.week_attendance.friday}    /></td>
              <td><ViewMode user={user} raw_data={user.week_attendance.saturday}  /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
