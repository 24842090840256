import React, { useEffect } from 'react';
import { Button, Paper, Typography, Modal } from '@mui/material';
import { Box, Stack } from '@mui/system';
import Timelogs from './partials/_timelogs';
import EditMode from './_edit_mode';
import DetailedMode from './_detailed_mode';
import Approval from './partials/_approval';

const DateAttendance = ({raw_data, user}) => {
  const [data, setData] = React.useState({...raw_data});
  const [edit_mode, setEditMode] = React.useState(false);
  const [show_more, setShowMore] = React.useState(false);

  const text_color = data?.status==="confirmed"?'#4caf50': data?.status==="unconfirmed" ? 'grey':'#ffc107'

  const onConfirm = () => {
    fetch(`/approval/${data.id}/confirm`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({})
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === 'success') {
        setData(data.data)
      } else {
        alert(data.errors);
      }
    })
    .catch((error) => {
      alert('Ooops! please try again later')
    });
  }

  return (
    <>
      {data.is_future == false && <>
        <Paper sx={{width: '200px', maxWidth:'20rem', margin:"0 auto", padding:2}} elevation={2}>
          <Stack direction={"column"} justifyContent={'center'} alignItems={'center'}>
            <Typography variant='h6' sx={{fontWeight:'bold'}} color={'#777777'}>{data?.recommended_status}</Typography>
            <Box sx={{margin:"1rem 0", display:'flex', flexDirection:"column"}}>
              {(data?.current_status==="confirmed" || data?.current_status==="semi-confirmed") && (
                <>
                <Box style={{border: `1px solid ${text_color}`, padding: "5px"}}>
                  {data?.current_status==="semi-confirmed" && <Typography
                    variant='h6'
                    sx={{fontWeight:'bold', textAlign: 'center'}}
                    color={text_color}
                  >
                    {data?.floating_status}
                  </Typography>}
                  <Stack direction={'row'} spacing={2} justifyContent='center' alignItems={'center'}>
                    {data?.approvals?.map((item, key)=>(
                      <Approval key={key} text_color={text_color} item={item} />
                    ))}
                  </Stack>
                </Box>
                </>
              )}
              {edit_mode == false && data?.current_status==="unconfirmed" && (
                <>
                  <Button variant='contained' onClick={onConfirm} sx={{margin:.5}} size='small'>Confirm</Button>
                  <Button variant='contained' onClick={()=>setEditMode(true)} sx={{margin:.5}} size='small' color='warning'>Edit</Button>
                </>
              )}
              {edit_mode == true && <EditMode user={user} data={data} onCancel={()=>setEditMode(false)} setData={setData} />}
            </Box>
            <Stack direction={'row'} spacing={2}>
              <Timelogs title='IN'  date={data?.date} logs={data?.in_logs}  to_show='first' />
              <Timelogs title='OUT' date={data?.date} logs={data?.out_logs} to_show='last' />
            </Stack>
            <Box>
              {show_more === false && <Button onClick={()=>setShowMore(true)}>more details</Button>}
            </Box>
          </Stack>
        </Paper>
        {show_more === true && <Modal open={true} onClose={() => setShowMore(false)}>
          <DetailedMode data={data} user={user} />
        </Modal>}
      </>}
    </>
  );
}

export default DateAttendance;
