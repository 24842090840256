import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment/moment';

export default ({key, text_color, item}) => {
  return(
    <Box key={key}>
      <Typography variant='subtitle1' color={text_color}> 
        By: {item.user.first_name} {item.user.last_name}
      </Typography>
      <Typography variant='subtitle2' align='center' color={'grey'}>
        {item.status}
      </Typography>
      <Typography variant='subtitle2' align='center' color={'grey'}>
        {moment(item.confirmed_at).format("h:mm:ss a")}
      </Typography>
    </Box>
  )
}
